import React from 'react'
import Header from './header'
import Footer from './footer'

import { useEffect } from 'react'
import { initGA } from 'utils/analytics'

export default ({ location, children, isFrontPage }) => {

    useEffect(() => {
        if (!window.GA_INITIALIZED) {
            initGA()
        }
    }, []);
    return (
        <div className="page-container">
            <Header location={location} isFrontPage={isFrontPage} />
            <main>
                {children}
            </main>
            <Footer />
        </div>
    )
}
