import React from 'react'

import { useIsMobile } from 'utils'

import AniLink from "gatsby-plugin-transition-link/AniLink"

export default ({
    children,
    to,
    dangerouslySetInnerHTML,
    className,
    onClick
}) => {
    const isMobile = useIsMobile()

    const Element = AniLink

    const props = {
        cover: true,
        duration: isMobile ? 0 : 0.7,
        direction: 'left',
        bg: '#15104D',
        to: to.startsWith('/') ? to : '/'+to,
        className,
        onClick,
        dangerouslySetInnerHTML,
    }

    if (to.startsWith('https') || to.startsWith('http')) {
        return (
            <a
                href={to}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                className={className}
                target={'_blank'}
                rel="noreferrer"
            >
                { children}
            </a>
        )
    }

    return (
        <Element 
            {...props}
        >
            { children }
        </Element>
    )
}