import React, { useContext, useState, useEffect } from "react"
import { MainContext } from "context/context"
import HeaderMenu from 'components/headerMenu'
import MainMenu from 'components/mainMenu'
import Link from 'components/link'
import { useIsMobile } from 'utils'

import logo from "../images/logo.svg"
import whiteLogo from "../images/logo-white.svg"

export default ({ location, isFrontPage }) => {
	const {
		isHeaderInverted,
		hasScrolled,
		resetHeader,
		checkInvertedHeaderOffsets,
		options,
		searchOpen,
		setSearchOpen
	} = useContext(MainContext)

	const isMobile = useIsMobile()

	useEffect(() => {
		setTimeout(() => {
			checkInvertedHeaderOffsets()
		}, 10)
	}, [])

	useEffect(() => {
		if(isMobile){
			if(~window.location.href.indexOf('?menu')){
				toggleMenu()
			}
		}
	}, [isMobile])

	const [menuOpen, setMenuOpen] = useState(false)
	const [isDirectory, setIsDirectory] = useState(false)

	const closeMenu = () => {
		setMenuOpen(false)
	}

	const toggleMenu = () => {
		if (menuOpen) {
			closeMenu()
		} else {
			setMenuOpen(true)
		}
	}

	const closeSearch = () => {
		setSearchOpen(false)
	}

	const toggleSearch = () => {
		if (searchOpen) {
			closeSearch()
		} else {
			const searchInput = document.getElementById('s')
			setTimeout(() => {
				searchInput.focus()
			}, 50)
			setSearchOpen(true)
		}
	}

	useEffect(() => {
		resetHeader()
		setIsDirectory(location.pathname.includes('directory'))
	}, [location])

	let headerClassName = 'header'

	if (isHeaderInverted) {
		headerClassName += ' inverted'
	}

	if (hasScrolled || searchOpen) {
		headerClassName += ' has-scrolled'
	}

	if (menuOpen) {
		headerClassName += ' menu-active'
	}

	return (
		<>
			<MainMenu 
				location={location} 
				active={menuOpen} 
			/>
			<header className={headerClassName}>
				<div className={searchOpen ? 'header-search open' : 'header-search'}>
					<div className="container">
						<button className="header-search-close" onClick={toggleSearch}>Close</button>
						<form method="get" action="/search">
							<input
								id="s"
								name="s"
								type="text"
								placeholder="What are you looking for?"
							/>
							<button type="submit">
								<i className="fe fe-search"></i>
							</button>
						</form>
					</div>
				</div>
				<div className="container">
					<div className="header-left">
						<button className={menuOpen ? 'header-menu-button open' : 'header-menu-button'} onClick={toggleMenu} aria-label="Main Menu">
							<svg width="52" height="52" viewBox="0 0 100 100">
								<path className="line line1" d="M20,33.3h60c0,0,14.5-0.1,14.5,29.3c0,8.8-3.6,11.6-9.3,11.6S75,69.1,75,69.1L25,30.2" />
								<path className="line line2" d="M20,49.6h60" />
								<path className="line line3" d="M20,66h60c0,0,14.5,0.1,14.5-29.3c0-8.8-3.6-11.6-9.3-11.6C79.6,25,75,30.2,75,30.2L25,69.1" />
							</svg>
						</button>
						<HeaderMenu />
					</div>
					<Link to="/" className="header-logo">
						<img src={logo} className="logo" alt="Pentridge" />
						<img src={whiteLogo} className="logo" alt="Pentridge" />
					</Link>
					<div className="header-right">
						<HeaderMenu />
						<button className="header-search-button" onClick={toggleSearch}>
							<i className="fe fe-search"></i>
						</button>
						<Link to="/directory" className="header-directory">
							<svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M31 31.5H18.6V19.1H31V31.5ZM12.4 31.5H0V19.1H12.4V31.5ZM31 12.9H18.6V0.5H31V12.9ZM6.2 12.9C4.55566 12.9 2.97866 12.2468 1.81594 11.0841C0.653212 9.92134 0 8.34434 0 6.7C0 5.05566 0.653212 3.47866 1.81594 2.31594C2.97866 1.15321 4.55566 0.5 6.2 0.5C7.84434 0.5 9.42134 1.15321 10.5841 2.31594C11.7468 3.47866 12.4 5.05566 12.4 6.7C12.4 8.34434 11.7468 9.92134 10.5841 11.0841C9.42134 12.2468 7.84434 12.9 6.2 12.9Z" fill="white" />
							</svg>
							Directory
						</Link>
					</div>
				</div>
				{ isDirectory &&
					<div className="header-parking">Parking available in Pentridge Coburg! <Link to="/plan-your-visit#planYourVisit-parking">Learn more</Link></div>
				}
			</header>
		</>
	)
}
