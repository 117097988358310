import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Link from 'components/link'

export default () => {
   const data = useStaticQuery(graphql`
        query headerMenu {
            siteFields {
                menu(id: "dGVybTo0") {
                    id
                    name
                    menuItems {
                        nodes {
                            label 
                            url
                        }
                    }
                }
            }
        }
    `)

    const menuItems = data.siteFields.menu.menuItems.nodes

    return (
        <ul>
            {menuItems.map((row, index) => {
                const {
                    url,
                    label
                } = row

                return (
                    <li key={index}>
                        <Link to={ url } dangerouslySetInnerHTML={{ __html: label }} />
                    </li>
                )
            })}
        </ul>
    )
}
