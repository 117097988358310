import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Link from 'components/link'
import { isActiveLink } from 'utils'
import { useIsMobile } from 'utils'

import FadeCollapse from 'components/formFields/fadeCollapse'

export default ({ location, active }) => {
    const [openMobileMenuItem, setOpenMobileMenuItem] = useState(null)
    const openMobileMenuItemRef = useRef(openMobileMenuItem)

    openMobileMenuItemRef.current = openMobileMenuItem

    const data = useStaticQuery(graphql`
        query mainMenu {
            siteFields {
                menu(id: "dGVybToyNw==") {
                    id
                    name
                    menuItems(first: 100, after: null) {
                        nodes {
                            childItems(first: 100, after: null) {
                                nodes {
                                    label
                                    url
                                }
                            }
                            id
                            label 
                            url
                        }
                    }
                }
            }
        }
    `)

    const isMobile = useIsMobile()

    const menuItems = data.siteFields.menu.menuItems.nodes

    const idsToFilter = [
        'bmF2X21lbnVfaXRlbTo0Njcx',
        'bmF2X21lbnVfaXRlbTo0Njcy',
        'bmF2X21lbnVfaXRlbTo0Njcz'
    ]

    const _menuItems = menuItems.filter(row => idsToFilter.indexOf(row.id) === -1)

    const handleParentItemClick = index => {
        if(isMobile){
            setOpenMobileMenuItem(openMobileMenuItemRef.current === index ? null : index)
        }
    }

    return (
        <nav className={active ? 'active' : ''}>
            <div className="container">
                <ul>
                    {_menuItems.map((row, index) => {
                        const {
                            url,
                            label,
                        } = row

                        const childItems = row.childItems.nodes

                        const childItemsJsx = (
                            <ul>
                                <div className={'line'} />
                                {childItems.map((row, _index) => {
                                    const {
                                        url,
                                        label
                                    } = row

                                    return (
                                        <li
                                            key={_index}
                                            className={isActiveLink(location, url) && url !== '/' ? 'active' : ''}
                                        >
                                            <Link
                                                to={url}
                                                dangerouslySetInnerHTML={{ __html: label }}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                        )

                        return (
                            <li
                                key={index}
                                className={isActiveLink(location, url) && url !== '/' ? 'active' : ''}
                                style={{
                                    transitionDelay: (index / 10) + 's'
                                }}
                            >
                                {childItems.length ?
                                    <>
                                        <a
                                            href={'javascript:void(0)'}
                                            className="hasSubMenu"
                                            onClick={() => handleParentItemClick(index)}
                                        >
                                            {label}{isMobile && <i className={'fe fe-chevron-down'} />}
                                        </a>
                                        {isMobile ?
                                            <FadeCollapse open={openMobileMenuItemRef.current === index}>
                                                {childItemsJsx}
                                            </FadeCollapse>
                                            :
                                            childItemsJsx
                                        }
                                    </>
                                    :
                                    <Link
                                        to={url}
                                        dangerouslySetInnerHTML={{ __html: label }}
                                    />
                                }
                            </li>
                        )
                    })}
                </ul>
            </div>
        </nav>
    )
}
